import React, { Component } from 'react';
import QuoteElement from './../QuoteElement/QuoteElement';
import diu_underline from './../../img/diu_underline.svg';
import './QuoteList.css';

class QuotesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quotes: [],
      quotesByBook: {}, // Use object to store quotes by book ID
      errorMsg: '',
    };
  }

  componentDidMount() {
    fetch('https://www.diuna.art/quotes/GetQuotes_v2.php')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        this.setState({ quotes: data }, () => this.filterQuotes());
      })
      .catch(error => {
        console.log(error);
        this.setState({ errorMsg: 'Error while retrieving data' });
      });
  }

  filterQuotes() {
    const { quotes } = this.state;
    const quotesByBook = {};

    // Group quotes by book ID
    for (const quote of quotes) {
      if (!quotesByBook[quote.book_id]) {
        quotesByBook[quote.book_id] = [];
      }
      quotesByBook[quote.book_id].push(quote);
    }

    this.setState({ quotesByBook });
  }

  render() {
    const { quotesByBook, errorMsg } = this.state;
    const { bookTitles } = this.props; // Access bookTitles passed from App.js

    return (
      <div>
        {errorMsg && <p className="error">{errorMsg}</p>}

        {/* Render each book section */}
        {bookTitles.map((title, index) => (
          <React.Fragment key={index + 1}>
            <p className="book_title" data-book-id={index + 1}>
              {title}
            </p>
            <img src={diu_underline} alt="line" />
            {/* Render quotes for the current book */}
            {quotesByBook[index + 1] && quotesByBook[index + 1].map(quote => (
              <QuoteElement key={quote.id} quote={quote} />
            ))}
          </React.Fragment>
        ))}

      </div>
    );
  }
}

export default QuotesList;