import React from 'react';
import diune_mark from './diune_mark_red1.jpg';
import './Footer.css';




export default function Footer() {
    return (
        <div className='diune_footer'>
            <p className='greetings-text'>
              Z pozdrowieniami dla twórców strony www.diuna.friko.pl która służyła fanom przez prawie 20 lat.
            </p>
            <img id='dletter' src={diune_mark} alt='Diune' />
            <p className='greetings-text'>
              Z pozdrowieniami dla jedynego słusznego współczesnego wydania, w jedynym słusznym tłumaczeniu ;)
            </p>
            <br />
        </div>
    )
}
