import React from 'react'
import './Header.css';
import diu_underline from './diu_underline.svg';



export default function Header() {

        const mainQuoteAuthor ='--litania przeciw strachowi z obrządku Bene Gesserit';
    
    return (
        <div>
            <header className="App-header">
                <p className='title'>DIUNA</p>
                <img src={diu_underline} alt='line'/>
                {/* <p className='subtitle'>CYTATY</p> */}
    
                <div className="mainQuoteBox">
                    <div className="mainQuoteText">
                        Nie wolno się bać, strach zabija duszę, strach to mała śmierć a wielkie unicestwienie. 
                        się Stawię mu czoło, niechaj przejdzie po mnie i przeze mnie. <br />
                        A kiedy przejdzie, obrócę oko swej jaźni na jego drogę. Którędy przeszedł tam niczego nie będzie - tylko Ja pozostanę.</div>
                    <div className="mainQuoteAuthor">{mainQuoteAuthor}</div>
                </div>

            </header>
            <br />
        </div>
    )
}
