import React, { useState, useEffect } from 'react';
import './App.css';
import QuotesList from './comp/QuoteList/QuoteList';
import Header from './comp/Header/Header';
import Footer from './comp/Footer/Footer';

// Array to store book titles for consistency
const bookTitles = [
  "Księga I - Diuna",
  "Księga II - Mesjasz Diuny",
  "Księga III - Dzieci Diuny",
  "Księga IV - Bóg Imperator Diuny",
  "Księga V - Heretycy Diuny",
  "Księga VI - Kapitularz Diuną",
];

function App() {
  const [activeBook, setActiveBook] = useState('1'); // State for currently active book in menu

  // useEffect to handle scroll events and update activeBook
  useEffect(() => {
    const handleScroll = () => {
      const bookElements = document.querySelectorAll('.book_title');

      // Iterate through book titles to find the one closest to the top of the viewport
      for (let i = bookElements.length - 1; i >= 0; i--) {
        const bookElement = bookElements[i];
        if (bookElement.getBoundingClientRect().top <= 0) {
          setActiveBook(bookElement.getAttribute('data-book-id'));
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Function to handle menu item clicks and scroll to the correct section
  const handleMenuClick = (bookId) => {
    setActiveBook(bookId);

    // If clicking a book link
    if (bookId !== 'Podziękowania') {
      const bookElement = document.querySelector(`[data-book-id="${bookId}"]`);
      if (bookElement) {
        window.scrollTo({
          top: bookElement.offsetTop,
          behavior: 'smooth' // Smooth scrolling animation
        });
      } 
    } else { // If clicking the Podziękowania link
      const footer = document.querySelector('.diune_footer');
      window.scrollTo({
        top: footer.offsetTop,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="App">
      <div className="floating-menu">
        {/* Generate menu items from bookTitles array */}
        {bookTitles.map((title, index) => (
          <button
            key={index + 1}
            className={`menu-item ${activeBook === (index + 1).toString() ? 'active' : ''}`}
            onClick={() => handleMenuClick((index + 1).toString())}
          >
            {title} 
          </button>
        ))}
        <button
          className={`menu-item ${activeBook === 'Podziękowania' ? 'active' : ''}`}
          onClick={() => handleMenuClick('Podziękowania')}
        >
          Podziękowania
        </button>
      </div>
      <Header />
      <QuotesList bookTitles={bookTitles} /> {/* Pass bookTitles to QuotesList */}
      <Footer />
    </div>
  );
}

export default App;