import React from 'react'
import './QuoteElement.css'

function QuoteElement({quote}) {

    return (

        <div className="quoteBox">
            <div className="quoteText">{quote.quote_text}</div>
            <div className="quoteAuthor">{quote.quote_author}</div>
        </div>

    )
}
export default QuoteElement